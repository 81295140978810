
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    url_server: 'https://nest.mywaterroute.xyz',
    // url_server: 'https://nest.mirutaaguas.com',
    // url_server: 'http://localhost:3000',
    email_js_service_id: 'service_g5vpwpc',
    email_js_template: 'template_prampqu',
    email_js_public_key: 'w1In5DJwac-12HLwl',
    firebaseConfig: {
        apiKey: "AIzaSyAlhoy7TD5p67_4BLU9ypUWR4wQXui2DAA",
        authDomain: "rutaswork.firebaseapp.com",
        projectId: "rutaswork",
        storageBucket: "rutaswork.appspot.com",
        messagingSenderId: "267107945734",
        appId: "1:267107945734:web:ecbdbd7644cb3665a4e287",
        measurementId: "G-E5PZDB5Z09"
    },
    fcmElectronSenderId: "267107945734"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
