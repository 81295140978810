/**
 * Created by Ing. Luis Alejandro Reyes Morales on 01/04/2021.
 *
 * email: inglreyesm@gmail.com
 * github: https://github.com/lreyesm
 * linkedin: https://linkedin.com/in/luis-alejandro-reyes-morales-9b672012a
 *
 */
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
    faFilePdf,
    faInbox,
    faBuilding,
    faKey,
    faHighlighter,
} from '@fortawesome/free-solid-svg-icons';

import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import {
    getItacDisplayColumns,
    getItacField,
    getItacFormControls,
    getItacPhotoFields,
    isItacFileField,
    Itac,
} from 'src/app/interfaces/itac';
import { ApiService } from 'src/app/services/api.service';
import { UtilsService } from 'src/app/services/utils.service';
import { Subscription } from 'rxjs';
import { Manager } from 'src/app/interfaces/manager';
import { priority_status } from 'src/app/interfaces/water-task';
import { Zone } from 'src/app/interfaces/zone';
import { Track } from 'ngx-audio-player';
import { Location } from '@angular/common';
import { DialogImageComponent } from '../share/dialog-image/dialog-image.component';
import { MatDialog } from '@angular/material/dialog';
import { IpcService } from '../../services/ipc.service';
import * as saveAs from 'file-saver';
import { Planning } from '../../interfaces/planning';
import { PlanningDetail } from '../../interfaces/planning-detail';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

@Component({
    selector: 'app-itac',
    templateUrl: './itac.component.html',
    styleUrls: ['./itac.component.scss'],
})
export class ItacComponent implements OnInit {
    msaapDisplayTitle = true;
    msaapDisplayPlayList = false;
    msaapDisplayVolumeControls = true;
    msaapDisplayRepeatControls = true;
    msaapDisplayArtist = false;
    msaapDisplayDuration = false;
    msaapDisablePositionSlider = true;

    // Material Style Advance Audio Player Playlist
    msaapPlaylist?: Track[];

    faFilePdf = faFilePdf;
    faInbox = faInbox;
    faBuilding = faBuilding;
    faKey = faKey;
    loading: boolean = false;
    itacId: string = '';
    itac?: Itac;

    itacFormData: FormGroup = getItacFormControls();

    itacSubscription$?: Subscription;

    percentage: number = 0;
    uploading = false;
    photoColumn: string = '';

    managers: Manager[] = [];
    zones: string[] = [];
    plannings: string[] = [];
    planningDetails: string[] = [];

    timerInputChanged: any;
    currentIndex: any = -1;
    showFlag: any = false;
    images: Array<object> = [];

    form: FormGroup;
    planningsControl: FormControl;

    /**
     * Constructs a new instance of the ItacComponent.
     * @param _apiService - The ApiService instance used for making API calls.
     * @param _utilsService - The UtilsService instance used for utility functions.
     * @param spinner - The NgxSpinnerService instance used for displaying spinners.
     * @param _electronService - The IpcService instance used for Electron-related functionality.
     * @param location - The Location instance used for managing the current browser URL.
     * @param router - The Router instance used for navigating between routes.
     * @param route - The ActivatedRoute instance used for accessing route parameters.
     * @param formBuilder - The FormBuilder instance used for building reactive forms.
     * @param matDialog - The MatDialog instance used for displaying dialog boxes.
     */
    constructor(
        private _apiService: ApiService,
        public _utilsService: UtilsService,
        private spinner: NgxSpinnerService,
        public _electronService: IpcService,
        public location: Location,
        private router: Router,
        private route: ActivatedRoute,
        public formBuilder: FormBuilder,
        private matDialog: MatDialog
    ) {
        this.form = this.formBuilder.group({
            image: [null],
        });
        this.route.params.subscribe((params) => {
            this.itacId = params['id'];
        });
        this.planningsControl = new FormControl();
    }

    /**
     * Initializes the component by performing the following actions:
     * 1. Shows a loading indicator.
     * 2. Fetches teams and users with specific roles from the API.
     * 3. Sets options and adds form controls.
     * 4. Checks if managers are already available; if not, fetches them from the API.
     * 5. If the component is not in 'new' mode, subscribes to the ITAC data and populates the form with the retrieved data.
     * 6. If the component is in 'new' mode, sets the manager from local storage and hides the loading indicator.
     * 
     * @returns {Promise<void>} A promise that resolves when the initialization is complete.
     */
    async ngOnInit(): Promise<void> {
        this.showLoading(true);
        await this._apiService.getTeams();
        await this._apiService.getUsers(['administrador', 'operario']);

        await this.setOptions();
        this.addFromControls();

        if (this._utilsService.managers.length) this.managers = this._utilsService.managers;
        else await this._apiService.getManagers();

        if (this.itacId != 'new') {
            this.itacSubscription$ = this._apiService
                .getItac(this.itacId)
                .subscribe(async (doc: any) => {
                    this.addData(doc);
                    this.setPhotos();
                    await this.setInputsValueChanged();
                    this.addFromControls();
                    this.showLoading(false);
                });
        } else {
            const manager = localStorage.getItem('manager');
            this.itacFormData.controls['gestor'].setValue(manager);
            this.showLoading(false);
        }
    }

    /**
     * Asynchronously sets the options for zones, plannings, and planning details.
     * 
     * This method fetches data from the API service for zones, plannings, and planning details.
     * It then processes the data by mapping and sorting the zones and plannings, and mapping the planning details.
     * 
     * @returns {Promise<void>} A promise that resolves when the options have been set.
     */
    async setOptions(): Promise<void> {
        this.zones = (await this._apiService.getZones())
            .map((zone: Zone) => `${zone.codigo_zona} - ${zone.zona}`)
            .sort();
        this.plannings = (await this._apiService.getPlannings())
            .map((planning: Planning) => `${planning.codigo_planning} - ${planning.planning}`)
            .sort();
        this.planningDetails = (await this._apiService.getPlanningDetails())
            .map((planningDetail: PlanningDetail) => `${planningDetail.planning_details}`)
    }

    
    /**
     * Sets up the form controls and subscribes to their value changes.
     * If a task is provided, it populates the form controls with the task's values.
     * 
     * @returns void
     */
    addFromControls(): void {
        if (this.itac) this.planningsControl.setValue(this.itac.planning);
        
        this.planningsControl.valueChanges.subscribe(async (value: string) => {
            await this.updatePlannings(value);
            if (this.plannings.includes(value)) {
                if (this.itac) {
                    if (this.itac.planning == value) return;
                    this.itac.planning = value;
                    await this.updateTasksPlanning(value);
                }
            }
        });
        this.itacFormData.controls['planning_details'].valueChanges.subscribe(async (value: string) => {
            await this.updateTasksPlanningDetails(value);
        });
    }

    /**
     * Updates the plannings based on the provided value.
     * 
     * @param value - The value used to filter the plannings.
     * @returns A promise that resolves when the plannings are updated.
     */
    async updatePlannings(value: string): Promise<void> { 
        const plannings = (await this._apiService.getPlannings())
        .map((planning: Planning) => `${planning.codigo_planning} - ${planning.planning}`);
        this.plannings = plannings.filter((planning: string)=> planning.includes(value));
        if (this._utilsService.isFieldValid(value)) {
            this.itacFormData.controls['planning'].setValue(value);
            const planningCode = value.split('-')[0].trim();
            const allPlannings = await this._apiService.getPlanningDetails();
            this.planningDetails = allPlannings.filter((detail: PlanningDetail) => detail.codigo_planning == planningCode)
                .map((planningDetails: PlanningDetail) => `${planningDetails.planning_details}`)
                .sort();
        }
    }

    async updateTasksPlanning(planning: string): Promise<void> { 
        const code = this.itac?.codigo_itac;
        if (code && planning) {
            const tasks = await this._apiService.getTasks([['codigo_de_geolocalizacion', '==', code]]);
            if (tasks.length < 1) return;
            let data: any = {};
            data['planning'] = planning;
            const ids = tasks.map((task) => task.id!);
            const res = await this._apiService.updateTasks(ids, data, true);
            if (res) {
                this._utilsService.openSnackBar('Planificación de tareas actualizados');
            }
            else {
                this._utilsService.openSnackBar('Error actualizando planificación tareas', 'error');
            }
        }
    }

    async updateTasksPendentLocation(pendent_location: boolean): Promise<void> { 
        const code = this.itac?.codigo_itac;
        if (code) {
            const tasks = await this._apiService.getTasks([['codigo_de_geolocalizacion', '==', code]]);
            if (tasks.length < 1) return;
            let data: any = {};
            data['pendent_location'] = pendent_location;
            const ids = tasks.map((task) => task.id!);
            const res = await this._apiService.updateTasks(ids, data, true);
            if (res) {
                this._utilsService.openSnackBar('Estado de localización de tareas actualizados');
            }
            else {
                this._utilsService.openSnackBar('Error actualizando estado de localización tareas', 'error');
            }
        }
    }

    async updateTasksPlanningDetails(planning_details: string): Promise<void> { 
        const code = this.itac?.codigo_itac;
        if (code && planning_details) {
            const tasks = await this._apiService.getTasks([['codigo_de_geolocalizacion', '==', code]]);
            if (tasks.length < 1) return;
            let data: any = {};
            data['planning_details'] = planning_details;
            const ids = tasks.map((task) => task.id!);
            const res = await this._apiService.updateTasks(ids, data, true);
            if (res) {
                this._utilsService.openSnackBar('Detalles de planificación de tareas actualizados');
            }
            else {
                this._utilsService.openSnackBar('Error actualizando detalles de planificación tareas', 'error');
            }
        }
    }

    addData(doc: any) {
        if (!doc) {
            this._utilsService.openSnackBar('Error obteniendo datos de ITAC', 'error');
            this.showLoading(false);
            return;
        }
        const itac = doc as Itac;
        itac.id = parseInt(this.itacId);
        this.itac = itac;
        this._utilsService.itacSelected = this.itac;
        if (this.itac!.prioridad != priority_status.HIBERNATE) {
            this.itac.end_hibernation_priority = this.itac!.prioridad || priority_status.LOW;
        }
        this.itacFormData = this._utilsService.getFormFromItac(this.itac!);
        if (this.itac.audio_detalle && this.itac.audio_detalle.includes('http')) {
            this.msaapPlaylist = [
                {
                    title: 'Detalles de ITAC',
                    link: this.itac.audio_detalle,
                    artist: 'Fontanero',
                    duration: 5,
                },
            ];
        }
    }

    /**
     * Lifecycle hook that is called when the component is destroyed.
     * 
     * This method performs cleanup by unsubscribing from the `itacSubscription$`
     * to prevent memory leaks.
     */
    ngOnDestroy(): void {
        this.itacSubscription$!.unsubscribe();
    }

    /**
     * Sets the photos for the ITAC component.
     */
    setPhotos() {
        if (this.itac) {
            const photoFields = getItacPhotoFields();
            this.images = [];
            for (const photoField of photoFields) {
                const photo = this.itac[photoField as keyof Itac]!;
                if (photo && typeof photo === 'string' && photo.includes('http')) {
                    this.images.push({
                        image: photo,
                        thumbImage: photo,
                        photoType: photoField,
                        title: `${photoField.toUpperCase().replace('_', ' ')} ${
                            this.itac[`descripcion_${photoField}` as keyof Itac]
                                ? '   -----   ' +
                                    this.itac[`descripcion_${photoField}` as keyof Itac]
                                : ''
                        }`,
                    });
                }
            }
        }
    }

    /**
     * Displays the image corresponding to the given photo type.
     * If the image URL is invalid, a snackbar with a warning message is shown.
     * @param photoType - The type of the photo to display.
     */
    showImage(photoType: string) {
        const photo_url: any = this.itac![photoType as keyof Itac];
        if (!this._utilsService.isValidImage(photo_url)) {
            this._utilsService.openSnackBar('No hay imágen disponible', 'warning');
            return;
        }
        let i = 0;
        for (const imageObject of this.images) {
            if ((imageObject as any).photoType == photoType) {
                this.currentIndex = i;
                this.showFlag = true;
                break;
            }
            i++;
        }
        const dialogConfig = {
            data: {},
            panelClass: 'carrousel',
        };
        let photosData = {
            photos: this.images,
            currentIndex: this.currentIndex,
        };
        dialogConfig.data = photosData;
        this.matDialog.open(DialogImageComponent, dialogConfig);
    }

    /**
     * Closes the event handler.
     * Sets the `showFlag` property to `false` and the `currentIndex` property to `-1`.
     */
    closeEventHandler() {
        this.showFlag = false;
        this.currentIndex = -1;
    }

    async setInputsValueChanged() {
        this.itacFormData.controls['prioridad'].valueChanges.subscribe(async (priority: any) => {
            // console.log('************* control prioridad changes *************');
            // console.log(priority);
            try {
                if (priority == priority_status.HIBERNATE) {
                    const endHibernationDate = await this._utilsService.openDateSelectorDialog(
                        'Seleccione fecha fin de hibernacion'
                    );
                    const endHibernationTime = await this._utilsService.openTimeSelectorDialog(
                        'Seleccione hora de fin de hibernacion'
                    );
                    if (endHibernationDate && endHibernationTime) {
                        const momentDate: moment.Moment = moment(endHibernationDate);
                        momentDate.set({
                            hour: endHibernationTime.getHours(),
                            minute: endHibernationTime.getMinutes(),
                            second: 0,
                        });
                        this.itacFormData.controls['hibernacion'].setValue(true);
                        this.itacFormData.controls['end_hibernation_date'].setValue(
                            momentDate.toDate()
                        );
                        this._utilsService.openSnackBar('Itac hibernada correctamente');
                    } else {
                        this._utilsService.openSnackBar(
                            'No se asigno la hibernación correctamente',
                            'error'
                        );
                        this.itacFormData.controls['prioridad'].setValue(
                            this.itacFormData.controls['end_hibernation_priority'].value
                        );
                    }
                }
            } catch (err) {}
        });
    }

    async selectFile(input_file: any, column: string) {
        this.showLoading(true);
        this.saveFormData();
        if (!this.itac?.codigo_itac || !this.itac?.gestor) {
            this._utilsService.openSnackBar(
                'Seleccione anomalía, gestor y numero de abonado',
                'error'
            );
            return;
        }
        this.photoColumn = column;
        input_file.click();
    }
    async uploadFile(event: any) {
        const files = event.target.files;
        const file = files.item(0);
        this.showLoading(false);
        if (file) {
            this.uploading = true;

            this.form.patchValue({
                image: file,
            });
            this.form?.get('image')?.updateValueAndValidity();

            await this.uploadImage();

            this.uploading = false;

            this.setPhotos();
        }
    }

    reload() {
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigate(['./'], { relativeTo: this.route });
    }

    showLoading(state: boolean) {
        this.loading = state;
        if (state) {
            this.spinner.show('itacSpinner', {
                type: this._utilsService.getRandomNgxSpinnerType(),
            });
        } else {
            this.spinner.hide('itacSpinner');
        }
    }

    async openPDF() {
        let noneValidImage = true;
        const photos = getItacPhotoFields();

        for (const photo of photos) {
            let key: keyof Itac = photo! as keyof Itac;
            if (this._utilsService.isValidImage(this.itac![key] as string)) noneValidImage = false;
        }
        if (noneValidImage) {
            const text = 'No hay imágenes disponibles para crear el pdf'
            this._utilsService.openSnackBar(text, 'warning');
            return;
        }
        this.showLoading(true);

        const pdfName = `ITAC_${this.itac?.codigo_itac}.pdf`;
        const blob = await this._apiService.getItacPdf(this.itac!.id!, pdfName);
        if (this._electronService.isElectronApp()) await this.saveLocalPdf(blob, pdfName);
        else saveAs(blob, pdfName);

        this.showLoading(false);
    }

    async saveLocalPdf(blob: Blob, pdfName: string) { 
        const companySelected = JSON.parse(localStorage.getItem('companyJson') || '');
        const managerSelected = JSON.parse(localStorage.getItem('managerJson') || '');
        const path = `C:\\Mi_Ruta\\Empresas\\${
            companySelected.nombre_empresa || companySelected.id
        }\\Gestores\\${managerSelected.gestor || managerSelected.id}\\fotos_itacs\\${
            this.itac!.codigo_itac
        }`;
        const pdfBase64 = await this._utilsService.convertBlobToBase64(blob);
        const message = {
            message: 'download_pdf',
            pdfBase64: pdfBase64,
            path: path,
            filename: pdfName,
        };
        this._electronService.sendMessageSync(message);
        this._electronService.sendMessage('open-folder', {
            path: `${path}\\${pdfName}`,
        });
    }

    openItacLocation() {
        if (this._utilsService.isClientUser()) {
            if (this.itac?.geolocalizacion) {
                this._utilsService.goToLink(
                    this._utilsService.getGeolocationUrl(this.itac?.geolocalizacion)
                );
            }
        } else {
            this.router.navigate(['/itac-location', this.itacId]);
        }
    }

    saveFormData() {
        const keys = Object.keys(this.itacFormData.controls);
        for (let key of keys) {
            let value = this.itacFormData.controls[key].value; //this.itac[key as keyof Itac];
            try {
                value = this._utilsService.getProcessedValue(key, value);
                if (!isItacFileField(key)) {
                    this.itac![key as keyof Itac] = value;
                }
            } catch (err) {
                console.log('============= err =============');
                console.log(err);
            }
        }
        this.itac!.last_modification_android = false;
        this.itac!['date_time_modified'] = new Date();
        if (!this.itac!['fecha_importacion']) {
            this.itac!['fecha_importacion'] = this.itac!['date_time_modified'];
        }
    }
    calculatePoints() {
        let totalPoints: number = 0;

        if (this.itac!.puntuacion_seccion1) {
            totalPoints += this.itac!.puntuacion_seccion1;
        }
        if (this.itac!.puntuacion_seccion2) {
            totalPoints += this.itac!.puntuacion_seccion2;
        }
        if (this.itac!.puntuacion_seccion3) {
            totalPoints += this.itac!.puntuacion_seccion3;
        }
        if (this.itac!.puntuacion_seccion4) {
            totalPoints += this.itac!.puntuacion_seccion4;
        }
        if (this.itac!.puntuacion_seccion5) {
            totalPoints += this.itac!.puntuacion_seccion5;
        }
        this.itac!.puntuacion = totalPoints;
    }

    async saveChanges() {
        this.showLoading(true);
        this.saveFormData();
        this.calculatePoints();
        if (this.itacId != 'new') {
            const result: boolean = await this._apiService.updateItac(this.itacId, this.itac);
            if (result) {
                this._utilsService.openSnackBar('Itac actualizada correctamente');
            } else {
                this._utilsService.openSnackBar('Error actualizando itac', 'error');
            }
        } else {
            try {
                const itacId = await this._apiService.addItac(this.itac!);
                if (itacId) {
                    this.itac!.id = itacId;
                    this.itacId = itacId;
                    this._utilsService.openSnackBar('Itac añadida correctamente');
                } else {
                    this._utilsService.openSnackBar('Error añadiendo itac', 'error');
                }
            } catch (error) {
                this._utilsService.openSnackBar('Error añadiendo itac', 'error');
            }
        }
        this.showLoading(false);
    }

    async selectDateAppointment() {
        try {
            const { startDate, endDate } = await this._utilsService.selectDateRange();

            this.itacFormData.controls['fecha_hora_cita'].setValue(startDate.toDate());
            this.itacFormData.controls['fecha_hora_cita_end'].setValue(endDate.toDate());
            this.itacFormData.controls['cita_pendiente'].setValue(true);

            this.itac!.nuevo_citas = this._utilsService.getNewDateString(startDate, endDate) 
            this.itacFormData.controls['nuevo_citas'].setValue(this.itac!.nuevo_citas);

            this.resetPriority();
        } catch (error) {
            this._utilsService.openSnackBar('Cita no seleccionada', 'warning');
        }
    }

    resetPriority(): void {
        if(this.itac && (this.itac.hibernacion || this.itac.prioridad == priority_status.HIBERNATE)){
            if(this.itac.end_hibernation_priority){
                this.itac.prioridad = this.itac.end_hibernation_priority;
            }
            else this.itac.prioridad = priority_status.LOW;
            this.itac.hibernacion = false;

            this.itacFormData.controls['hibernacion'].setValue(false);
            this.itacFormData.controls['prioridad'].setValue(this.itac.prioridad);
        }
    }

    async clearDate() {
        if (
            await this._utilsService.openQuestionDialog(
                'Confirmación',
                '¿Seguro desea eliminar la cita?'
            )
        ) {
            this.itacFormData.controls['nuevo_citas'].setValue('');
            this.itacFormData.controls['cita_pendiente'].setValue(false);
            this.itacFormData.controls['fecha_hora_cita'].setValue(null);
            this.itacFormData.controls['fecha_hora_cita_end'].setValue(null);
        }
    }

    async sendMessage() {
        if (
            this.itacFormData.controls['telefono_fijo_administracion'].value ||
            this.itacFormData.controls['telefono_fijo_encargado'].value ||
            this.itacFormData.controls['telefono_fijo_presidente'].value ||
            this.itacFormData.controls['telefono_movil_administracion'].value ||
            this.itacFormData.controls['telefono_movil_encargado'].value ||
            this.itacFormData.controls['telefono_movil_presidente'].value
        ) {
            this.itac!.telefono_fijo_administracion =
                this.itacFormData.controls['telefono_fijo_administracion'].value;
            this.itac!.telefono_fijo_encargado =
                this.itacFormData.controls['telefono_fijo_encargado'].value;
            this.itac!.telefono_fijo_presidente =
                this.itacFormData.controls['telefono_fijo_presidente'].value;
            this.itac!.telefono_movil_administracion =
                this.itacFormData.controls['telefono_movil_administracion'].value;
            this.itac!.telefono_movil_encargado =
                this.itacFormData.controls['telefono_movil_encargado'].value;
            this.itac!.telefono_movil_presidente =
                this.itacFormData.controls['telefono_movil_presidente'].value;
            await this._utilsService.openWhatsappMessageDialog(undefined, this.itac!);
        } else {
            this._utilsService.openSnackBar('No hay teléfonos disponibles', 'error');
        }
    }

    async openSection1() {
        const result = await this._utilsService.openSection1Dialog(this.itac!);
    }
    async openSection2() {
        const result = await this._utilsService.openSection2Dialog(this.itac!);
    }
    async openSection3() {
        const result = await this._utilsService.openSection3Dialog(this.itac!);
    }
    async openSection4() {
        const result = await this._utilsService.openSection4Dialog(this.itac!);
    }
    async openSection5() {
        const result = await this._utilsService.openSection5Dialog(this.itac!);
    }
    async openStateSummary() {
        const result = await this._utilsService.openSelectorDialog('Resumen de estado', [
            'Excelente',
            'Mal estado',
        ]);
        if (result) {
            if (result == 'Excelente') {
                this.setExcelentSection3();
                this.setExcelentSection4();
                this.setExcelentSection5();
            } else if (result == 'Mal estado') {
                this.setBadStateSection3();
                this.setBadStateSection4();
                this.setBadStateSection5();
            }
        }
        this.saveChanges();
    }

    setExcelentSection3() {
        this.itacFormData.controls['puntuacion_seccion3'].setValue(1.5);
    }
    setBadStateSection3() {
        this.itacFormData.controls['puntuacion_seccion3'].setValue(0);
    }

    setExcelentSection4() {
        this.itacFormData.controls['tubo_de_alimentacion'].setValue('Excelente');
        this.itacFormData.controls['colector'].setValue('Excelente');
        this.itacFormData.controls['tuberias_de_entrada_contador'].setValue('Excelente');
        this.itacFormData.controls['tuberias_de_salida_contador'].setValue('Excelente');
        this.itacFormData.controls['puntuacion_seccion4'].setValue(2);
    }
    setBadStateSection4() {
        this.itacFormData.controls['tubo_de_alimentacion'].setValue('Mal estado');
        this.itacFormData.controls['colector'].setValue('Mal estado');
        this.itacFormData.controls['tuberias_de_entrada_contador'].setValue('Mal estado');
        this.itacFormData.controls['tuberias_de_salida_contador'].setValue('Mal estado');
        this.itacFormData.controls['puntuacion_seccion4'].setValue(0);
    }

    setExcelentSection5() {
        this.itacFormData.controls['valvula_general'].setValue('Sin comprobar');
        this.itacFormData.controls['extras_valvula_general'].setValue('EN BUEN ESTADO');
        this.itacFormData.controls['valvula_antiretorno'].setValue('Sin comprobar');
        this.itacFormData.controls['extras_valvula_antiretorno'].setValue('EN BUEN ESTADO');
        this.itacFormData.controls['valvula_entrada'].setValue('Sin comprobar');
        this.itacFormData.controls['extras_valvula_entrada'].setValue('EN BUEN ESTADO');
        this.itacFormData.controls['valvula_salida'].setValue('Sin comprobar');
        this.itacFormData.controls['extras_valvula_salida'].setValue('EN BUEN ESTADO');
        this.itacFormData.controls['puntuacion_seccion5'].setValue(5);
    }
    setBadStateSection5() {
        this.itacFormData.controls['valvula_general'].setValue('Sin comprobar');
        this.itacFormData.controls['extras_valvula_general'].setValue('EN MAL ESTADO');
        this.itacFormData.controls['valvula_antiretorno'].setValue('Sin comprobar');
        this.itacFormData.controls['extras_valvula_antiretorno'].setValue('EN MAL ESTADO');
        this.itacFormData.controls['valvula_entrada'].setValue('De Batería M.M.V');
        this.itacFormData.controls['extras_valvula_entrada'].setValue('EN MAL ESTADO');
        this.itacFormData.controls['valvula_salida'].setValue('De Batería M.M.V');
        this.itacFormData.controls['extras_valvula_salida'].setValue('EN MAL ESTADO');
        this.itacFormData.controls['puntuacion_seccion5'].setValue(0);
    }

    async uploadImage() {
        var formData: any = new FormData();
        formData.append('image', this.form?.get('image')?.value);
        formData.append('data', this.photoColumn);

        try {
            const itacData = await this._apiService.uploadItacImage(formData, this.itac!);
            this.itac = itacData;
            this.itacFormData.controls[this.photoColumn].setValue(
                this.itac![this.photoColumn as keyof Itac]
            );
        } catch (err) {
            console.log('************* err *************');
            console.log(err);
        }
    }

    async rotateImage(photoType: string) {
        this.uploading = true;

        const res = await this._apiService.rotateItacImage(this.itac!, photoType);
        if (res) {
            const photo_url = this.itac![photoType as keyof Itac];
            const timestamp = new Date().getTime();
            const img = document.getElementById(photoType);
            (img as any).src = `${photo_url}?t=` + timestamp;
        } else this._utilsService.openSnackBar('Error rotando foto', 'error');

        this.uploading = false;
    }
    
    /**
     * Handles the change event for the pendent location toggle.
     * 
     * @param event - The change event emitted by the MatSlideToggle.
     * 
     * Logs the event and the current value of the 'pendent_location' control in the task form data.
     */
    async pendentLocationChange(event: MatSlideToggleChange): Promise<void> {
        console.log('============= event =============');
        console.log(event);
        const pendent_location = this.itacFormData.controls['pendent_location'].value;
        console.log('============= pendent_location =============');
        console.log(pendent_location);
        if (this.itac) {
            if (this.itac.pendent_location == pendent_location) return;
            this.itac.pendent_location = pendent_location;
            await this.updateTasksPendentLocation(pendent_location);
        }
    }
}
