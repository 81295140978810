/**
 * Created by Ing. Luis Alejandro Reyes Morales on 01/04/2021.
 *
 * email: inglreyesm@gmail.com
 * github: https://github.com/lreyesm
 * linkedin: https://linkedin.com/in/luis-alejandro-reyes-morales-9b672012a
 *
 */
import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';

import * as moment from 'moment';
import { Moment } from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import {
    MiRutaUser,
    getMiRutaUserDisplayColumns,
    getMiRutaUserExcelExportColumns,
    getMiRutaUserExcelFieldName,
    getMiRutaUserField,
    getMiRutaUserFieldType,
} from 'src/app/interfaces/mi-ruta-user';
import { ApiService } from 'src/app/services/api.service';
import { MessagingService } from 'src/app/services/messaging.service';
import { UtilsService } from 'src/app/services/utils.service';
import { WindowRefService } from 'src/app/services/window-ref.service';
import * as XLSX from 'xlsx';
import { faInbox } from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'app-users',
    templateUrl: './users.component.html',
    styleUrls: ['./users.component.scss'],
})
export class UsersComponent implements OnInit {
    faInbox = faInbox;
    tableName: string = 'users';
    loading: boolean = true;
    miRutaUsersInPage: MiRutaUser[] = [];
    dataSource: MatTableDataSource<MiRutaUser> = new MatTableDataSource();

    length = 0; //task count in current table
    pageSize = 200; //limit of query
    lastPageIndex = 0;
    pageSizeOptions: number[] = [10, 50, 100, 200, 500];

    displayedColumns: string[] = getMiRutaUserDisplayColumns();
    fixedColumns = [];
    displayedColumnsField: string[] = this.displayedColumns.map((displayedColumn: string) =>
        getMiRutaUserField(displayedColumn)
    );
    clickedRows = new Set<MiRutaUser>();

    lastSelectedRow: number = -1;

    filteredColumn?: string;
    orderedColumn?: string;

    loadingText = 'Cargando...';

    constructor(
        private _apiService: ApiService,
        private _utilsService: UtilsService,
        private _windowRefService: WindowRefService,
        private _spinner: NgxSpinnerService,
        private _messagingService: MessagingService
    ) {}

    async ngOnInit(): Promise<void> {
        await this.getMiRutaUsers();
    }

    showLoading(state: boolean) {
        this.loading = state;
        if (state) {
            this._spinner.show('innerSpinner', {
                type: this._utilsService.getRandomNgxSpinnerType(),
            });
        } else {
            this._spinner.hide('innerSpinner');
        }
    }

    setMiRutaUsersInTable(miRutaUsers: MiRutaUser[]) {
        this.miRutaUsersInPage = miRutaUsers;
        // this.dataSource.data = miRutaUsers.slice(0, this.sliceSize);
        this.dataSource.data = miRutaUsers;
        // this.dataSource = new TableVirtualScrollDataSource(miRutaUsers);// infiniteScroll
        // console.log(this.dataSource);
        this.showLoading(false);
    }

    async getMiRutaUsers() {
        this.setMiRutaUsersInTable(await this.selectMiRutaUsers());
    }
    async selectMiRutaUsers() {
        this.showLoading(true);
        localStorage.setItem('users', '');
        const docs = await this._apiService.getUsers([]);
        this.length = docs.length;
        return docs;
    }

    searchValue(event: any) {
        try {
            const filterValue: string = event;
            this.dataSource!.filter = filterValue.trim().toLowerCase();
        } catch (err) {}
    }

    async deleteMiRutaUsers() {
        let errorIds = [];
        if (this.clickedRows.size == 0) {
            this._utilsService.openSnackBar('Debe seleccionar al menos una operario', 'warning');
            return;
        }
        const result = await this._utilsService.openQuestionDialog(
            'Confirmación',
            '¿Desea eliminar las operarios seleccionados?'
        );
        if (result) {
            this._spinner.show();
            this.loadingText = `Eliminando operarios ...`;
            let rowIndexes = new Set<number>();
            const oldDataSource = this.dataSource!.data;
            this.clickedRows.forEach(async (row) => {
                console.log(row.id);

                const index = this.dataSource!.data.indexOf(row, 0);
                if (index > -1) {
                    rowIndexes.add(index);
                }
            });
            let miRutaUsers = [];
            let deleteCount = 0;
            for (let i = 0; i < oldDataSource.length; i++) {
                if (!rowIndexes.has(i)) {
                    miRutaUsers.push(oldDataSource![i]);
                } else {
                    this.loadingText = `Eliminando operarios ${++deleteCount} de ${
                        rowIndexes.size
                    }`;
                    if (!(await this._apiService.onDeleteUser(oldDataSource[i].id!.toString()))) {
                        console.log('****************** deleteMiRutaUser error *************');
                        errorIds.push(oldDataSource[i].id);
                    } else {
                        console.log('****************** deleteMiRutaUser success *************');
                    }
                }
            }
            if (errorIds.length > 0) {
                this._utilsService.openSnackBar(`Hubo errores eliminando operarios`, 'error');
            } else {
                this._utilsService.openSnackBar(`Usuarios eliminados correctamente`);
            }
            this.length -= rowIndexes.size;
            this.setMiRutaUsersInTable(miRutaUsers);
            this.clickedRows.clear();
            this._spinner.hide();
        }
    }

    async filterBy(column: string) {
        this.filteredColumn = getMiRutaUserField(column);
        if (
            getMiRutaUserFieldType(this.filteredColumn) == 'Date' ||
            getMiRutaUserFieldType(this.filteredColumn) == 'Timestamp'
        ) {
            const dates = await this._utilsService.openDateRangeSelectorDialog(
                'Seleccione rango de fechas'
            );
            this.onDateSelected(dates);
        } else {
            const options: any[] = this.dataSource.data.map(
                (miRutaUser: MiRutaUser) => miRutaUser[this.filteredColumn as keyof MiRutaUser]
            );
            const result = await this._utilsService.openSelectorDialog(
                'Seleccione opción',
                options
            );
            if (result) {
                const company = localStorage.getItem('company');
                const where = [
                    // ['companies', 'array-contains', company],
                    [this.filteredColumn, '==', result],
                ];
                let docs = [];
                try {
                    docs = await this._apiService.getDocuments<MiRutaUser>('user', where);
                } catch (err) {
                    console.log('============= err =============');
                    console.log(err);
                }
                this.length = docs.length;
                this.setMiRutaUsersInTable(docs);
            }
        }
    }
    async onDateSelected(dateRange: Date[]) {
        if (dateRange) {
            const startDate: Moment = moment(dateRange[0]);
            const startDateString = startDate.format('YYYY-MM-DD HH:mm:ss');
            let endDate: Moment = moment(dateRange[1]);
            if (!endDate) {
                endDate = startDate;
            }
            const endDateString = endDate.add(1, 'days').format('YYYY-MM-DD HH:mm:ss'); //plus one to filter range

            const where = [
                [this.filteredColumn!, '>=', startDate.toDate()],
                [this.filteredColumn!, '<', endDate.toDate()],
            ];
            let docs = [];
            try {
                docs = await this._apiService.getDocuments<MiRutaUser>('user', where);
            } catch (err) {
                console.log('============= err =============');
                console.log(err);
            }
            this.length = docs.length;
            this.setMiRutaUsersInTable(docs);
        } else {
            this._utilsService.openSnackBar('Rango fechas invalido', 'error');
        }
    }

    async orderBy(event: any) {
        const column = event.column;
        let orderType = event.orderType;
        if (orderType.toLowerCase().includes('asc')) {
            orderType = 'asc';
        } else {
            orderType = 'desc';
        }
        const orderedColumn = getMiRutaUserField(column);

        this.showLoading(true);
        const company = localStorage.getItem('company');
        // const where = [['companies', 'array-contains', company]];
        const miRutaUsers = await this._apiService.getDocuments<MiRutaUser>('user', undefined, [
            orderedColumn,
            orderType,
        ]);
        this.setMiRutaUsersInTable(miRutaUsers);
    }

    async addNewRow(event: any) {
        const miRutaUser = await this._utilsService.openMiRutaUserDialog('');
        if (miRutaUser) {
            this.dataSource.data.push(miRutaUser);
            const miRutaUsers = this.dataSource.data;
            this.dataSource.data = [];
            this.dataSource.data = miRutaUsers;
        }
    }
    async doubleClickedRow(row: any) {
        const miRutaUser = await this._utilsService.openMiRutaUserDialog(row.id);
        if (miRutaUser) {
            this.dataSource.data[row.rowIndex] = miRutaUser;
            const miRutaUsers = this.dataSource.data;
            this.dataSource.data = [];
            this.dataSource.data = miRutaUsers;
        }
    }

    clickedRow(receivedEvent: any) {
        const row = receivedEvent.row;
        const event = receivedEvent.event;
        const rowIndex = receivedEvent.rowIndex;

        const previousRow = this.lastSelectedRow;
        this.lastSelectedRow = rowIndex;

        if (event.button === 0) {
            if (!event.ctrlKey && !event.shiftKey) {
                this.clickedRows.clear();
                this.toggleRow(row);
            } else if (event.ctrlKey) {
                this.toggleRow(row);
            }
            if (event.shiftKey) {
                this.selectRowsBetweenIndexes(previousRow, rowIndex);
            }
        }
        if (this._windowRefService.nativeWindow.getSelection) {
            //remove selection in table with shift
            if (this._windowRefService.nativeWindow.getSelection().empty) {
                // Chrome
                this._windowRefService.nativeWindow.getSelection().empty();
            } else if (this._windowRefService.nativeWindow.getSelection().removeAllRanges) {
                // Firefox
                this._windowRefService.nativeWindow.getSelection().removeAllRanges();
            }
        }
    }
    selectRowsBetweenIndexes(lastSelectedRow: number, rowIndex: number) {
        let start, end;
        if (rowIndex > lastSelectedRow) {
            start = lastSelectedRow;
            end = rowIndex;
        } else {
            end = lastSelectedRow;
            start = rowIndex;
        }
        for (let i = start; i <= end; i++) {
            this.clickedRows.add(this.dataSource!.data[i]);
        }
    }
    toggleRow(row: any) {
        if (this.clickedRows.has(row)) {
            this.clickedRows.delete(row);
        } else {
            this.clickedRows.add(row);
        }
    }

    exportMiRutaUsersInTable() {
        this.exportExcel(this.dataSource!.data);
    }
    exportExcel(users: any[]) {
        let excelFormatTasks = [];
        for (let userIteration of users) {
            let user: any = {};
            const columns = getMiRutaUserExcelExportColumns();
            columns.forEach((column) => {
                user[`${column}`] = this._utilsService.tableDataPipe(
                    userIteration[getMiRutaUserExcelFieldName(column)]
                );
            });
            excelFormatTasks.push(user);
        }
        const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(excelFormatTasks);

        /* generate workbook and add the worksheet */
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

        /* save to file */
        XLSX.writeFile(wb, 'Usuarios_Exportados.xlsx');
    }
}
