import { FormControl, FormGroup } from '@angular/forms';
import { Company } from './company';
import { Footprint } from './footprint';

import { MyLatLng } from './lat-lng';
import { Manager } from './manager';
import { Journal } from './journal';
import { UserSettings } from './user-settings';
import { UserColumnsOrder } from './user-columns-order';

export interface MiRutaUser {
    id: number;
    username: string;
    password: string;
    role: number;
    photo: string;
    phone: string;
    address: string;
    developer: boolean;
    superusuario: boolean;
    administrador: boolean;
    operario: boolean;
    cliente: boolean;
    descripcion: string;
    date_time_created: Date;
    date_time_modified: Date;
    photo_server_path: string;
    permisos: string;
    user_columns_order: UserColumnsOrder;
    settings: UserSettings;
    geolocalizacion: MyLatLng;
    restricciones: string;
    codigo: string;
    nombre: string;
    apellido1: string;
    apellido2: string;
    companies: Company[];
    managers: Manager[];
    footprints: Footprint[];
    journal: Journal[];
}

export function getMiRutaUserDisplayColumns(): string[] {
    const displayedColumns: string[] = [
        'Código',
        'Nombre',
        'Apellido 1',
        // 'Apellido 2',
        'Email',
        'Teléfono',
        'Superusuario',
        'Administrador',
        'Operario',
        'Cliente',
        // 'Descripción',
        'Fecha de creación',
    ];
    return displayedColumns;
}

export function getMiRutaUserField(field_name: string): string {
    let value: string = '';
    switch (field_name) {
        case 'Código':
            value = 'codigo';
            break;
        case 'Nombre':
            value = 'nombre';
            break;
        case 'Apellido 1':
            value = 'apellido1';
            break;
        case 'Apellido 2':
            value = 'apellido2';
            break;
        case 'Email':
            value = 'username';
            break;
        case 'Contraseña':
            value = 'password';
            break;
        case 'Teléfono':
            value = 'telefono';
            break;
        case 'Superusuario':
            value = 'superusuario';
            break;
        case 'Administrador':
            value = 'administrador';
            break;
        case 'Operario':
            value = 'operario';
            break;
        case 'Cliente':
            value = 'cliente';
            break;
        case 'Descripción':
            value = 'descripcion';
            break;
        case 'Fecha de creación':
            value = 'date_time_created';
            break;
        case 'Fecha de modificación':
            value = 'date_time_modified';
            break;
    }
    return value;
}

export function getMiRutaUserFieldName(field_name: string): string {
    let value: string = '';
    switch (field_name) {
        case 'codigo':
            value = 'Código';
            break;
        case 'nombre':
            value = 'Nombre';
            break;
        case 'apellido1':
            value = 'Apellido 1';
            break;
        case 'apellido2':
            value = 'Apellido 2';
            break;
        case 'username':
            value = 'Email';
            break;
        case 'password':
            value = 'Contraseña';
            break;
        case 'telefono':
            value = 'Teléfono';
            break;
        case 'superusuario':
            value = 'Superusuario';
            break;
        case 'administrador':
            value = 'Administrador';
            break;
        case 'operario':
            value = 'Operario';
            break;
        case 'cliente':
            value = 'Cliente';
            break;
        case 'descripcion':
            value = 'Descripción';
            break;
        case 'date_time_created':
            value = 'Fecha de creación';
            break;
        case 'date_time_modified':
            value = 'Fecha de modificación';
            break;
    }
    return value;
}

export function getMiRutaUserFormControls(): FormGroup {
    const miRutaUserFormData: FormGroup = new FormGroup({
        id: new FormControl(),
        username: new FormControl(),
        password: new FormControl(),
        role: new FormControl(),
        photo: new FormControl(),
        phone: new FormControl(),
        address: new FormControl(),
        superusuario: new FormControl(),
        administrador: new FormControl(),
        operario: new FormControl(),
        cliente: new FormControl(),
        descripcion: new FormControl(),
        date_time_created: new FormControl(),
        date_time_modified: new FormControl(),
        photo_server_path: new FormControl(),
        permisos: new FormControl(),
        user_columns_order: new FormControl(),
        settings: new FormControl(),
        geolocalizacion: new FormControl(),
        restricciones: new FormControl(),
        codigo: new FormControl(),
        nombre: new FormControl(),
        apellido1: new FormControl(),
        apellido2: new FormControl(),
        columns_tasks: new FormControl(),
        columns_itacs: new FormControl(),
        columns_counters: new FormControl(),
        columns_routes: new FormControl(),
        columns_radius_modules: new FormControl(),
        companies: new FormControl(),
        managers: new FormControl(),
        footprints: new FormControl(),
        journal: new FormControl(),
    });
    return miRutaUserFormData;
}

export function getMiRutaUserFieldType(field_name: string): string {
    //in bigquery
    let value: string = '';
    switch (field_name) {
        case 'password':
            value = 'string';
            break;
        case 'date_time_modified':
            value = 'Date';
            break;
        case 'photo':
            value = 'string';
            break;
        case 'photo_server_path':
            value = 'string';
            break;
        case 'id':
            value = 'number';
            break;
        case 'nombre':
            value = 'string';
            break;
        case 'permisos':
            value = 'string';
            break;
        case 'telefono':
            value = 'string';
            break;
        case 'geolocalizacion':
            value = 'string';
            break;
        case 'superusuario':
            value = 'boolean';
            break;
        case 'administrador':
            value = 'boolean';
            break;
        case 'operario':
            value = 'boolean';
            break;
        case 'cliente':
            value = 'boolean';
            break;
        case 'date_time_created':
            value = 'Date';
            break;
        case 'descripcion':
            value = 'string';
            break;
        case 'codigo':
            value = 'string';
            break;
        case 'restricciones':
            value = 'string';
            break;
        case 'username':
            value = 'string';
            break;
        case 'apellido1':
            value = 'string';
            break;
        case 'apellido2':
            value = 'string';
            break;
        case 'companies':
            value = 'string_array';
            break;
        case 'managers':
            value = 'string_array';
            break;
    }
    return value;
}

export function getMiRutaUserExcelFieldName(excel_field: string): string {
    let value: string = '';
    switch (excel_field.trim().toLowerCase()) {
        case 'código':
            value = 'codigo';
            break;
        case 'codigo':
            value = 'codigo';
            break;
        case 'nombre':
            value = 'nombre';
            break;
        case 'apellido 1':
            value = 'apellido1';
            break;
        case 'apellido 2':
            value = 'apellido2';
            break;
        case 'email':
            value = 'username';
            break;
        case 'password':
            value = 'password';
            break;
        case 'teléfono':
            value = 'telefono';
            break;
        case 'telefono':
            value = 'telefono';
            break;
        case 'superusuario':
            value = 'superusuario';
            break;
        case 'administrador':
            value = 'administrador';
            break;
        case 'operario':
            value = 'operario';
            break;
        case 'cliente':
            value = 'cliente';
            break;
        case 'descripción':
            value = 'descripcion';
            break;
        case 'descripcipn':
            value = 'descripcion';
            break;
        case 'fecha de creación':
            value = 'date_time_created';
            break;
        case 'fecha de creacion':
            value = 'date_time_created';
            break;
        case 'fecha de modificación':
            value = 'date_time_modified';
            break;
        case 'fecha de modificacion':
            value = 'date_time_modified';
            break;
    }
    return value;
}

export function getMiRutaUserExcelExportColumns(): string[] {
    const columns = [
        'Código',
        'Nombre',
        'Apellido 1',
        'Apellido 2',
        'Email',
        'Teléfono',
        'Superusuario',
        'Administrador',
        'Operario',
        'Cliente',
        'Descripción',
        'Fecha de creación',
        'Fecha de modificación',
    ];
    return columns;
}
